import { Loader } from "kui-utils"
import { isObservable, toJS } from "mobx"

function cleanObjectForIndexDB(value: any): any {
  if (typeof value === "undefined") return null
  if (value instanceof File) return value

  if (isObservable(value)) {
    return cleanObjectForIndexDB(toJS(value))
  }

  if (Array.isArray(value)) {
    return value.map((item) => cleanObjectForIndexDB(item))
  }

  if (value !== null && typeof value === "object") {
    const plainObject: any = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const key in value) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(key)) {
        const objectValue = value[key]

        if (typeof objectValue !== "function" && objectValue !== undefined) {
          plainObject[key] = cleanObjectForIndexDB(objectValue)
        }
      }
    }
    return plainObject
  }

  return value
}

let transactionQueue: Promise<any> = Promise.resolve()

export function addToIndexDBWithQueue(
  db: any,
  storeName: string,
  data: any,
  loader: Loader,
) {
  transactionQueue = transactionQueue
    .then(
      () =>
        new Promise((resolve, reject) => {
          if (db && db.objectStoreNames.contains(storeName) && data) {
            const transaction = db.transaction(storeName, "readwrite")
            const store = transaction.objectStore(storeName)

            // eslint-disable-next-line no-param-reassign
            data.id = 1

            const formattedData = cleanObjectForIndexDB(data)

            const request = store.put(formattedData)

            request.onsuccess = function () {
              resolve(request)
            }

            request.onerror = function () {
              loader.setError(
                "Не удалось сохранить данные в локальное хранилище",
              )
              reject()
            }
          }
        }),
    )
    .catch((error) => {
      console.error("Ошибка в очереди транзакций:", error)
    })
}

export const readFromIndexDB = async (
  db: any,
  name: string,
  loader: Loader,
): Promise<any> => {
  if (db.objectStoreNames.contains(name)) {
    const transaction = db.transaction(name, "readonly").objectStore(name)

    const request = transaction.getAll()

    return new Promise((resolve, reject) => {
      request.onerror = function () {
        reject()
        loader.setError("Не удалось сохранить данные в локальное хранилище")
      }

      request.onsuccess = function () {
        resolve(request.result?.[0])
      }
    })
  }
  return null
}

export const initIndexDB = (
  dbName: string,
  onupgradeneeded: (db: any) => void,
  loader: Loader,
  setIndexDB: (db: any) => void,
) => {
  const dbRequest = indexedDB.open(dbName, 1)

  dbRequest.onerror = () => {
    loader.setError(
      "Ошибка при открытии хранилища на устройстве. Данные не будут сохраняться локально",
    )
  }

  dbRequest.onsuccess = () => {
    const db = dbRequest.result
    setIndexDB(db)
  }

  dbRequest.onupgradeneeded = () => onupgradeneeded(dbRequest.result)
}

export const addIndexDBStore = (db: any, name: string) => {
  if (db && !db.objectStoreNames.contains(name)) {
    db.createObjectStore(name, { keyPath: "id" })
  }
}

export const clearIndexStore = (db: any, name: string) => {
  if (db) {
    const transaction = db.transaction(name, "readwrite")
    const objectStore = transaction.objectStore(name)
    objectStore.clear()
  }
}
