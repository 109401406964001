import React from "react"
import { observer } from "mobx-react"
import { InspectionTypeButtonProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import inspectionTypeLabels from "./content"
import OptionButton from "../../../../../components/common/OptionButton"
import { formFieldsName, formStateName } from "../../../../../utils/constants"
import { clearIndexStore } from "../../../../../utils/indexDB"

const InspectionTypeButton = ({ type, onClick }: InspectionTypeButtonProps) => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const handleClick = () => {
    if (
      (formStore.fields?.type && formStore.fields?.type !== type) ||
      !inspectionsStore.canUpdateDB
    ) {
      Array.from([formStateName, formFieldsName]).forEach((name) => {
        clearIndexStore(inspectionsStore.indexDB, name)
      })
      formStore.resetForm()
    }
    inspectionsStore.setCanUpdateDB(true)
    formStore.updateFormFields({
      type,
      apartmentId: inspectionsStore.apartmentId,
    })
    onClick()
  }

  return (
    <OptionButton
      label={inspectionTypeLabels[type]}
      value={type}
      onClick={handleClick}
    />
  )
}

export default observer(InspectionTypeButton)
