import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { apartmentFields, ApartmentModel } from "kui-crm"
import { FillingListStore } from "kui-crm_actions"
import ApartmentAgent from "../../agent/ApartmentAgent"
import { ApartmentParams } from "../../types/store/apartments"
import ApartmentMetersStore from "./ApartmentMetersStore"
import InspectionsStore from "./InspectionsStore"

class ApartmentStore {
  apartmentInfo: ApartmentParams | null

  inspectionsStore: InspectionsStore

  metersStore: ApartmentMetersStore

  fillingStore: FillingListStore

  loader: Loader

  constructor() {
    this.apartmentInfo = null
    this.inspectionsStore = new InspectionsStore(this)
    this.metersStore = new ApartmentMetersStore(this)
    this.fillingStore = new FillingListStore()
    this.loader = new Loader(true)
    makeAutoObservable(this)
  }

  fetchApartment = async (id: number) => {
    this.loader.startLoading()

    const response = await to(ApartmentAgent.getById(id))

    resHandler(response, this.loader, this.updateApartmentInfo)
  }

  updateApartmentInfo = (res: ApartmentModel) => {
    this.apartmentInfo = {
      id: res.id,
      folderNumber: res.folder_number,
      imageUrl:
        res.main_image?.image_medium_url || res.main_image?.image_url || "",
      landlordName: `${res.owner?.first_name} ${res.owner?.last_name} ${res.owner?.middle_name[0]}.`,
      landlordPhone: res.owner?.phone?.replace(/\D/g, "") || null,
      communalServicesAccount: res.financial_personal_account || "",
      company: res.administrative_company
        ? {
            ...res.administrative_company,
            operatingAccount: res.payer_code,
          }
        : null,
      actualRentalContractId:
        res.rental_contracts.find((contract) => contract.status === "Open")
          ?.id || null,
      payerCode: res.payer_code,
      additionalTerms: "",
      countryOfResidence: null,
      taxesType: null,
      contracts: res.rental_contracts.map((contract) => ({
        id: contract.id,
        number: contract.number,
      })),
      hasRentalContract: !!res.renter,
      hasServiceContract: !!res.owner,
      ...apartmentFields.getApartmentDescriptionInfo(res),
    }
  }

  resetApartmentPage = () => {
    this.apartmentInfo = null
    this.metersStore = new ApartmentMetersStore(this)
    this.loader = new Loader(true)
    this.inspectionsStore.reset()
  }
}

export default ApartmentStore
