import React from "react"
import { InspectionTypes } from "kui-crm"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import { InspectionFormFields, InspectionFormHandler } from "../../types"
import InitialInspectionForm from "../../variants/InitialInspectionForm"
import InventoryInspectionForm from "../../variants/InventoryInspectionForm"
import TransferInspectionForm from "../../variants/TransferInspectionForm"
import RegularInspectionForm from "../../variants/RegularInspectionForm"
import { InspectionFormProps } from "./types"
import { formFieldsName, formStateName } from "../../../../utils/constants"
import { clearIndexStore } from "../../../../utils/indexDB"

const inspectionFormByType = (handleSubmit: InspectionFormHandler) => ({
  initial: <InitialInspectionForm handleSubmit={handleSubmit} />,
  inventory: <InventoryInspectionForm handleSubmit={handleSubmit} />,
  transfer: <TransferInspectionForm handleSubmit={handleSubmit} />,
  regular: <RegularInspectionForm handleSubmit={handleSubmit} />,
})

const InspectionForm = (props: InspectionFormProps) => {
  const { handleCreate } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const handleSubmit = async (
    type: InspectionTypes,
    data: InspectionFormFields,
  ) => {
    const inspectionId = await inspectionsStore.createInspection(type, data)
    if (inspectionId) handleCreate()
    formStore.resetForm()
    Array.from([formStateName, formFieldsName]).forEach((name) => {
      clearIndexStore(inspectionsStore.indexDB, name)
    })
  }

  return inspectionFormByType(handleSubmit)[
    (formStore.fields?.type || "initial") as keyof typeof inspectionFormByType
  ]
}

export default observer(InspectionForm)
