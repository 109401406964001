export const defaultApartmentImage = "/images/LivingAreaSmall.svg"

export const meterCheckValueMessage =
  "Есть отклонение в показании счетчика. Значение должно быть не больше"

export const fillingWarningMessage =
  "Это наполнение отсутствовала в предыдущий осмотр"

export const formStateName = "formState"

export const formFieldsName = "formFields"

export const inspectionCreationFormName = "inspectionCreation"
