import React from "react"
import {
  FillingCheckParams,
  InspectionFillingCard,
  InspectionFillingListFields,
} from "kui-crm_actions"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../components/common/FormWrapper"
import { fillingWarningMessage } from "../../../../utils/constants"

const InspectionFillingScreen = () => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<InspectionFillingListFields>({
    // @ts-ignore
    values: {
      ...formStore.fields,
    },
  })

  const handleSubmit = (data: InspectionFillingListFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Проверьте наполнение"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {formStore.fields?.fillingList.map(
          (filling: FillingCheckParams, index: number) => (
            <Grid item xs={12} key={filling.id}>
              <InspectionFillingCard
                filling={filling}
                index={index}
                form={form}
                warningLabel={fillingWarningMessage}
              />
            </Grid>
          ),
        )}
      </Grid>
    </FormWrapper>
  )
}

export default observer(InspectionFillingScreen)
