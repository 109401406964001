import { useEffect, useState } from "react"
import { useFillingList, useMetersValues } from "kui-crm_actions"
import useApartmentStore from "./useApartmentStore"

const useInspectionData = () => {
  const apartmentStore = useApartmentStore()
  const { apartmentInfo, inspectionsStore, metersStore, fillingStore } =
    apartmentStore
  const [isMetersLoaded, setIsMetersLoader] = useState(false)
  const [isFillingLoaded, setIsFillingLoaded] = useState(false)
  const formStore = inspectionsStore.creationForm
  const inspectionType = formStore.fields?.type
  const canBeLoad = inspectionType
    ? inspectionType === "regular" || inspectionType === "transfer"
    : undefined
  const fillingLoading = fillingStore.loader.isLoading
  const metersLoading = metersStore.loader.isLoading

  const handleMetersLoad = () => setIsMetersLoader(true)

  const handleFillingLoad = () => setIsFillingLoaded(true)

  useEffect(() => {
    if (
      isMetersLoaded &&
      isFillingLoaded &&
      !fillingLoading &&
      !metersLoading
    ) {
      inspectionsStore.setCanLoadDB(true)
    }
  }, [isMetersLoaded, isFillingLoaded, fillingLoading, metersLoading])

  useMetersValues(formStore, metersStore, canBeLoad, handleMetersLoad)

  useFillingList(
    apartmentInfo?.id || null,
    formStore,
    fillingStore,
    canBeLoad,
    handleFillingLoad,
  )
}

export default useInspectionData
